<template>
    <User  :users="usersFiltered"/>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import User from "../components/admin/Users";

export default {
  data () {
    return {
      userFilters:{},
    }
  },
  name: "users",
  components: {
    User
  },

  async created() {
    Object.assign(this.userFilters, this.storeUserFilters);
    this.filterUsers(this.userFilters);
    this.clearUsersFilter();
    await this.retriveUsers();
    await this.retriveRoles();
  },

  computed: {
    ...mapGetters({
      usersFiltered: "usersFiltered"
    }),
     ...mapState({
      'storeUserFilters':'userfilter'
    }),
  },

  watch: {
    usersFiltered: {
      handler(){
        Object.assign(this.userFilters, this.storeUserFilters);
      }, deep: true 
    }
  },
  methods: {
    ...mapActions(['retriveUsers','filterUsers','clearUsersFilter']),
    ...mapActions("role", ["retriveRoles"]),
  }
};
</script>