<template>
  <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
    <div class="my-border" v-if="users.length>0">
      <v-card-title class="py-0 my-0 customlightgray">
        <v-row class="ma-0 pa-0 py-2 justify-center text-left">
          <v-col class="pa-0 ma-auto" cols="7" sm="4" md="4" lg="4" order="1" order-md="1">
            <div class="font-weight-medium text-left text--secondary">  
                <div class="ma-auto pa-auto">
                  <span class="ma-0 pa-1">{{$t('Users')}}</span>
                  <span style=" border-radius: 22px; font-size:0.6em;"
                          class="custom-color-accent pa-1">{{users.length}}
                    </span>
                </div>
            </div>
          </v-col>
          <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
            cols="12" 
            sm="12"
            md="4"
            lg="4">  
            <v-text-field
              v-model="search"
              class="ma-0 pa-0 mx-2"
              append-icon="mdi-magnify"
              @keyup="searchUsers(search)"
              :label="$t('search')"            
              dense
              outlined
              hide-details
            >
              <template v-slot:prepend-inner>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on='on' v-bind="attrs" @click="dialog_adv=true" class="text--secondary">mdi-cog</v-icon>
                  </template>
                  <span>{{$t("advanced")}}</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
          <v-col class="text-right pa-0 my-2 ma-md-auto" cols="5" sm="8" md="4" lg="4" order="2" order-md="3"> 
            <v-btn class="ma-0 pa-auto custom-color-accent" @click="AddUserSection=!AddUserSection" v-if="$checkPermission('Users.Create')" depressed>
              {{$t("New User")}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <!-- dialog advanced search -->
      <v-dialog v-model="dialog_adv" max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 gray">
            <span class="font-weight-light">{{$t("advanced")}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-row class="ma-0">
               <v-col cols="12" class="ma-0 py-0" > 
                  <v-autocomplete 
                    multiple
                    clearable
                    v-model="filters.role"
                    :items="roles.map(item=>{return {value:item.id,text:item.name}})"
                    :label="$t('role')"
                    :placeholder="$t('role')"
                    dense
                    outlined
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-autocomplete
                    small-chips
                    multiple
                    clearable
                    v-model="filters.status"
                    :items="status"
                    :label="$t('status')"
                    :placeholder="$t('status')"
                    dense
                    outlined
                    return-object
                  >
                    <template v-slot:selection="data">
                      <v-chip :input-value="data.selected"
                          class="custom-color-accent" small>
                          <strong>{{ $t(data.item.text) }}</strong>
                      </v-chip>
                    </template>
                  </v-autocomplete>
               </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 py-0">
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="dialog_adv = false">{{$t("close")}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="findUsers">{{$t("search")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- /dialog advanced search -->
      <!-- Filters -->    
      <v-divider  v-show="filterList.length>0"></v-divider>       
      <v-row class="pa-0 ma-0 white" v-show="filterList.length>0">
        <v-col class="pa-0 ma-0" cols="12">
          <v-scroll-x-transition group hide-on-leave>
          <v-tooltip v-for="(f, i) in filterList" :key="i" right>
            <template v-slot:activator="{ on, attrs }">
              <v-chip  v-on='on' v-bind="attrs"
                class="ma-1"
                color="custom-color-accent"
                close
                small
                close-icon="mdi-delete"
                @click:close="removeFilter(f)" >
                  {{f}}
                </v-chip>
              </template>
              <span>{{$t("removefilter")}}</span>
            </v-tooltip>
          </v-scroll-x-transition>
        </v-col>
      </v-row> 
      <!-- /Filters -->
      <v-divider></v-divider>
      <v-row class="ma-0 pa-0">
        <v-scroll-x-transition  >
          <v-col v-show="AddUserSection" key="tans-1" cols="12" sm="12" md="8" lg="3" class="ma-0 pa-auto ">
            <v-card class="mx-auto pa-3 customlightgray my-border text-center" height="100%" outlined>
              <span class="ma-auto text--secondary" style="font-size:1.3em">
                {{$t("New User")}}
              </span> 
              <v-form  v-on:submit.prevent ref="form"
                v-model="valid"
                lazy-validation >
                <v-text-field class="mx-0 my-3 pa-0"
                  prepend-icon="mdi-account"
                  v-model="newItem.username"
                  :placeholder="$t('username')"
                  :label="$t('username')"
                  outlined
                  :rules="[rules.required, rules.minU]"
                  dense
                  hide-details
                ></v-text-field>
                <v-text-field class="mx-0 my-3 pa-0"
                  v-model="newItem.last_name"
                  prepend-icon="mdi-card-account-details"
                  :placeholder="$t('lastname')"
                  :label="$t('lastname')"
                  outlined
                  :rules="[rules.required, rules.minU]"
                  dense
                  hide-details
                ></v-text-field>
                <v-text-field class="mx-0 my-3 pa-0"
                  :placeholder="$t('firstname')"
                  :label="$t('firstname')"
                  v-model="newItem.first_name"
                  prepend-icon="mdi-card-account-details-outline"
                  outlined
                  :rules="[rules.required, rules.minU]"
                  dense
                  hide-details
                ></v-text-field>
                <v-text-field class="mx-0 my-3 pa-0"
                  prepend-icon="mdi-email-outline"
                  :placeholder="$t('email')"
                  :label="$t('email')"
                  v-model="newItem.email"
                  :rules="[rules.required, rules.email]"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <v-text-field class="mx-0 my-3 pa-0"
                  prepend-icon="mdi-phone-outline"
                  :placeholder="$t('telephone')"
                  :label="$t('telephone')"
                  v-model="newItem.phone1"
                  outlined
                  :rules="[rules.required, rules.minU]"
                  dense
                  hide-details
                ></v-text-field>
                <v-text-field class="mx-0 my-3 pa-0"
                  prepend-icon="mdi-map-outline"
                  :placeholder="$t('address')"
                  :label="$t('address')"
                  v-model="newItem.address"
                  :rules="[rules.required, rules.minU]"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <v-autocomplete class="mx-0 my-3 pa-0"
                  :items="roles"
                  :placeholder="$t('role')"
                  :label="$t('role')"
                  item-value="id"
                  item-text="name"
                  prepend-icon="mdi-bookmark-multiple"
                  v-model="newItem.role"
                  :rules="[rules.required]"
                  outlined
                  dense
                  hide-details
                ></v-autocomplete>
                <v-select class="mx-0 my-3 pa-0"
                  :items="status"
                  :placeholder="$t('status')"
                  :label="$t('status')"
                  v-model="newItem.active"
                  prepend-icon="mdi-alert-circle-check"
                  outlined
                  :rules="[rules.required]"
                  dense
                  hide-details
                ></v-select>
                <v-text-field class="mx-0 my-3 pa-0"
                  :type="show ? 'text' : 'password'"
                  prepend-icon="mdi-lock-outline"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  :placeholder="$t('password')"
                  :label="$t('password')"
                  v-model="newItem.password"
                  :rules="[rules.required, rules.min]"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-form>
              <v-card-actions class="pa-0 ma-0">
                <v-spacer></v-spacer>
                <v-btn
                  class=" secondary"
                  depressed
                  @click="AddUserSection=false"
                >{{$t("close")}}</v-btn>
                <v-btn
                  @click="create()"
                  class=" custom-color-accent"
                  depressed
                >{{$t("add")}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-scroll-x-transition>
        <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 pa-auto" v-for="user in users" :key="user.id">
          <v-card v-if="user.id!=editedItem.id || !$checkPermission('Users.Update')" class="mx-auto customlightgray my-border text-center" :id="user.username" outlined @click="showEditItemSection(user)">
                <v-avatar class="custom-color-accent ma-auto" size="55">
                  <span class="text-uppercase">{{ user.last_name.charAt(0) }}{{ user.first_name.charAt(0)}}</span>
                </v-avatar>
                <v-list-item-title class="headline mb-1 text-capitalize">{{user.last_name}} {{user.first_name}}</v-list-item-title>
                <v-list-item-subtitle>{{user.email}}</v-list-item-subtitle>

            <v-card-actions>
            
              <v-chip class="ma-1 disabled" v-if="user.role && roles.length>0">{{roles.filter(item=>item.id==user.role)[0].name}}</v-chip>
              <v-chip
                :class="user.active=='1'?'ma-1 custom-color-accent':'ma-1 warning'"
              >{{user.active=='1'?$t('active'):$t('suspended')}}</v-chip>
            </v-card-actions>
          </v-card>
            <v-card v-if="user.id==editedItem.id && $checkPermission('Users.Update')" class="mx-auto px-3 customlightgray my-border text-center" height="100%" outlined>
              <v-avatar class="custom-color-accent ma-auto" size="55">
                <span class="text-uppercase">{{ editedItem.last_name.charAt(0) }}{{ editedItem.first_name.charAt(0)}}</span>
              </v-avatar>
                    <v-text-field class="mx-0 my-3 pa-0"
                      v-model="editedItem.username"
                      :placeholder="$t('username')"
                      :label="$t('username')"
                      prepend-icon="mdi-account"
                      outlined
                      :rules="[rules.required, rules.minU]"
                      dense 
                      hide-details
                    ></v-text-field>
                    <v-text-field class="mx-0 my-3 pa-0"
                      v-model="editedItem.last_name"
                      :placeholder="$t('lastname')"
                      :label="$t('lastname')"
                      prepend-icon="mdi-card-account-details"
                      outlined
                      :rules="[rules.required, rules.minU]"
                      dense 
                      hide-details
                    ></v-text-field>
                    <v-text-field class="mx-0 my-3 pa-0"
                      v-model="editedItem.first_name"
                      :placeholder="$t('firstname')"
                      :label="$t('firstname')"
                      prepend-icon="mdi-card-account-details-outline"
                      outlined
                      :rules="[rules.required, rules.minU]"
                      dense 
                      hide-details
                    ></v-text-field>
                    <v-text-field class="mx-0 my-3 pa-0"
                      v-model="editedItem.email"
                      prepend-icon="mdi-email-outline"
                      :placeholder="$t('email')"
                      :label="$t('email')"
                      outlined
                      :rules="[rules.required, rules.email  ]"
                      dense 
                      hide-details
                    ></v-text-field>
                    <v-text-field class="mx-0 my-3 pa-0"
                      v-model="editedItem.phone1"
                      prepend-icon="mdi-phone-outline" 
                      :placeholder="$t('telephone')"
                      :label="$t('telephone')"
                      outlined
                      :rules="[rules.required, rules.minU]"
                      dense 
                      hide-details
                    ></v-text-field>
                    <v-text-field class="mx-0 my-3 pa-0"
                      v-model="editedItem.address"
                      prepend-icon="mdi-map-outline"
                      :placeholder="$t('address')"
                      :rules="[rules.required, rules.minU]"
                      :label="$t('address')"
                      outlined
                      dense 
                      hide-details
                    ></v-text-field> 
                    <v-autocomplete class="mx-0 my-3 pa-0"
                      :items="roles"
                      v-model="editedItem.role"
                      :placeholder="$t('role')"
                      :label="$t('role')"
                      prepend-icon="mdi-bookmark-multiple"
                      item-value="id"
                      item-text="name"
                      outlined
                      :rules="[rules.required]"
                      dense 
                      hide-details
                    ></v-autocomplete>
                    <v-select class="mx-0 my-3 pa-0"
                      :items="status"
                      v-model="editedItem.active"
                      value="Active"
                      :placeholder="$t('status')"
                      :label="$t('status')"
                      prepend-icon="mdi-alert-circle-check"
                      outlined
                      :rules="[rules.required]"
                      dense 
                      hide-details
                    ></v-select>
              
              <v-card-actions class="pt-0 mt-0">
                <v-spacer></v-spacer>
                <v-btn
                    class=" secondary"
                    depressed
                    @click.stop="hideEditedItemSection()"
                  >{{$t("close")}}</v-btn>
                  <v-btn
                    @click="update()"
                    class=" custom-color-accent"
                    depressed
                  >{{$t("edit")}}</v-btn>
              </v-card-actions>
          </v-card>
        </v-col>
      </v-row>  
    </div>      
  </v-card>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  props: ["users"],

  data() {
    return {
      search: "",
      valid: true,
      show: false,
      editedItem: {},
      dialog_adv: false,
      newItem: {},
      filterList:[],
      AddUserSection: false,
      status: [
        { value: 1, text: "active" },
        { value: 0, text: "suspended" }
      ],
      rules: {
          required: value => !!value || this.$t('required'),
          min: v => (!v ||v.length >= 8) || this.$t('mincharacters'),
          minU: v => (!v ||v.length >= 3) || this.$t('mincharacters3'), 
          email: v => (!v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || this.$t('mailmustbevalid')
      },
      filters: {
        status:[],
        role:[],
        searchWord:'',
      },
    };
  },

  computed: {
    ...mapState("role", {
      roles: "roles"
    }),
    ...mapState({
      loading: "loading",storeFilters:"userfilter"
    }),
  },
  created() {
    Object.assign(this.filters, this.storeFilters);
    this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
    this.filterList = this.filterList.filter(el=> el != "");
  },
  watch:{
    storeFilters: {
      handler(){
          Object.assign(this.filters, this.storeFilters);  
          this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
                this.filterList = this.filterList.filter(el=> el != "");

      }, deep: true 
    },
  },
  methods: {
    findUsers(){
      this.close();
      this.filterUsers(this.filters);
    },
    searchUsers(word){
      this.filters.searchWord=word;
      this.filterUsers(this.filters);
    },
    showEditItemSection(item) {
      if(!this.$checkPermission('Users.Update')){
         this.$root.$emit("callAlert", {
            text: this.$t("notallowed"),
            type: "dark",
            alert: true
          });
      }
      this.editedItem = {};
      Object.assign(this.editedItem ,item);
      //this.editedItem = JSON.parse(JSON.stringify(item));
      //!this.editedItem.roles[0] ? this.editedItem.roles.push({ id: "" }) : "";
    },
    hideEditedItemSection() {
      this.editedItem = {};
    },

    update() {
      this.updateUser(this.editedItem).then(
        () => {
          let payload = {
            role: this.editedItem.role,
            user: this.editedItem.id
          };
          payload.role != "" ? this.assignRoleUser(payload) : "";
          this.hideEditedItemSection();

          this.$root.$emit("callAlert", {
            text: "User Information Updated Successfully",
            type: "dark",
            alert: true
          });
        },
        () => {
          this.$root.$emit("callAlert", {
            text: this.$t("Something went wrong"),
            type: "warning",
            alert: true
          });
        }
      );
    },

    removeFilter(item){
      Object.keys(this.filters).forEach((key)=>{
      if( !Array.isArray(this.filters[key]) && (this.filters[key].text==item))
        this.filters[key]={text:"",value:""};        
      else if( !Array.isArray(this.filters[key]) && (this.filters[key]==item))
        this.filters[key]="";           

      else if(Array.isArray(this.filters[key]) && this.filters[key].map(a=>a.text).includes(item))
      this.filters[key]= this.filters[key].filter(a=>a.text!=item);

      else if(Array.isArray(this.filters[key]) && this.filters[key].includes(item))
      this.filters[key]= this.filters[key].filter(a=>a!=item);
      });
      this.filterUsers(this.filters);
    }, 
    close() {
      this.dialog = false;
      this.dialog_adv = false;
    },
    create() {
      (this.$refs.form.validate())? this.addUser(this.newItem).then(
        r => {
          let payload = {
            role: this.newItem.role,
            user: r.data.id
          };
          payload.role != "" ? this.assignRoleUser(payload) : "";
          this.AddUserSection = false;
          this.$root.$emit("callAlert", {
            text: this.$t("Role Added Successfully"),
            type: "success",
            alert: true
          });
        },
        () => {
          this.$root.$emit("callAlert", {
            text: this.$t("Something went wrong"),
            type: "warning",
            alert: true
          });
        }
      ):''
    },
    ...mapActions(["updateUser", "assignRoleUser", "addUser","filterUsers"]),

  }
};
</script>
<style scoped>
</style>